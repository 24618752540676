export default function getString(stringName) {
    const strings = [
        {
            'name' : 'errorLoginPassword',
            'text' : 'Błędny login lub hasło'
        },
        {
            'name' : 'registerSuccess',
            'text' : 'Zostałeś zarejestrowany. Teraz możesz się zalogować.'
        },
        {
            'name' : 'unConfirmationEmail',
            'text' : 'Nie udało się potwierdzić Twojego emaila.'
        },
        {
            'name' : 'confirmationEmail',
            'text' : 'Twoje konto zostało potwierdzone.'
        },
        {
            'name' : 'confirmationEarlier',
            'text' : 'Twoje konto zostało już wcześniej zweryfikowane.'
        },
        {
            'name' : 'sessionEnd',
            'text' : 'Twoja sesja wygasła i zostałeś wylogowany.'
        },
        {
            'name' : 'newPasswordSet',
            'text' : 'Twoje nowe hasło zostało ustawione.'
        },
        {
            'name' : 'problemChangePassword',
            'text' : 'Nie udało się zmienić Twojego e-maila. Spróbuj ponownie później.'
        },
        {
            'name' : 'resetPasswordSend',
            'text' : 'E-mail z linkiem do resetowania hasła został wysłany.'
        },
        {
            'name' : 'emailNotFound',
            'text' : 'Podany e-mail nie istnieje w naszej bazie danych.'
        },
        {
            'name' : 'serverProblem',
            'text' : 'Coś poszło nie tak, prosimy spróbować ponownie później.'
        },
        {
            'name' : 'badValidate',
            'text' : 'Problem z poprawnością danych.'
        },
        {
            'name' : 'newAdmin',
            'text' : 'Konto administratora zostało utworzone.'
        },
        {
            'name' : 'messagePush',
            'text' : 'Wiadomość push została wysłana'
        },
        {
            'name' : 'newTrade',
            'text' : 'Nowa branża została dodana.'
        },
        {
            'name' : 'updateTrade',
            'text' : 'Branża została zaaktualizowana'
        },
        {
            'name' : 'access',
            'text' : 'Brak posiadasz wystarczających uprawnień.'
        },
        {
            'name' : 'changeSettingMap',
            'text' : 'Ustawienia mapy zostały zmienione.'
        },
        {
            'name' : 'problem',
            'text' : 'Coś poszło nie tak, prosimy spróbować później'
        },
        {
            'name' : 'addExport',
            'text' : 'Excel został zaimportowany do bazy danych'
        }
    ]

    let result = 'Not found';
    strings.map(string => string.name === stringName ? result = string.text : null);

    return result;
}