import React, { useState, useEffect } from 'react'

const SelectItems = ( props ) => {
    let [ selected, setSelected ] = useState([]);


    useEffect(() => {
        if(props.value && (props.value).length > 0) {
            setSelected(props.value)
        }
    }, [props.value])

    const addSelected = (item) => {
        if(props.limit >= props.value || !props.limit) {
            let status = true;
            selected && selected.filter(sel => {
                if(sel.id == item.id && sel.type == item.type) {
                    status = false;
                } 
            })
    
            if(status) {
                setSelected([...selected, item])
                props.handle([...selected, item])
            }
        }
    }

    const removeSelected = (item) => {
        let newSelected = selected.filter(sel => {
            if(sel.id !== item.id) {
                return sel;
            }else{
                if(sel.type !== item.type) {
                    return sel;
                }
            }
        })
        setSelected(newSelected)
        props.handle(newSelected)
    }

    const checkClass = (itemData) => {
        let newClass = ""
        selected.filter(item => {
            if(item.id == itemData.id && item.type == itemData.type) {
                newClass = "disabled";
            }
        })
        return newClass;
    }

    return (
        <div className={ `col-${ props.column } select-items` } >
            <div className="form-group bmd-form-group">
                <label className={props.error ? "bmd-label-floating error" : "bmd-label-floating"} > { props.label } </label>
                <div className="box">
                    <div className="col">
                        {props.data && props.data.map((itemData, index) => (
                        <div className={"row-item " + checkClass(itemData)} key={index} onClick={ () => addSelected(itemData)}>
                            {itemData.type == "image" &&
                                <img className="image" src={itemData.src} />
                            }
                            {itemData.type == "svg" &&
                                <img className="image" src={itemData.src} />
                            }
                            {itemData.type !== "image" && itemData.type !== "svg" &&
                                <p className="type">[{itemData.type}]</p>
                            }
                            <span>{ itemData.name }</span>
                        </div>
                        ))}
                    </div>
                    <div className="col">
                        {selected && selected.map((sel, i) => (
                        <div className="row-item" key={i} onClick={ () => removeSelected(sel)}>
                            {sel.type == "image" &&
                                <img className="image" src={sel.src} />
                            }
                            {sel.type == "svg" &&
                                <img className="image" src={sel.src} />
                            }
                            {sel.type !== "image" && sel.type !== "svg" &&
                                <p className="type">[{sel.type}]</p>
                            }
                            <span>{ sel.name }</span>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>   
    );
}

export default SelectItems;
