import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import VideoRepeater from './../components/VideoRepeater';

import { useSelector, useDispatch } from 'react-redux'

const LibraryVideos = () => {
    const dispatch = useDispatch();
    let galleryVideosRedux = useSelector(state => state.galleryVideos);
    let [ galleryVideos, setGalleryVideos ] = useState('');
    let [ activeIndex, setActiveIndex ] = useState(99999999999);
    let media = useSelector(state => state.media);

    useEffect(() => {
        setGalleryVideos(galleryVideosRedux);
    }, [galleryVideosRedux])

    const addNewVr = () => {
        setActiveIndex(99999999999)
        var newGalleryVideos = [''];
        galleryVideos && galleryVideos.map(item => newGalleryVideos.push(item))
        setGalleryVideos(newGalleryVideos);
        setActiveIndex(0);
    }

    const addToRedux = (data) => {
        dispatch({ type: 'GALLERY_VIDEOS', value: data })
    }

    return (
        <div className="library-vr library-video">
            <div className="actions">
                <button type="button" className={"btn"} onClick={ () => addNewVr() }>Dodaj</button> 
            </div>
            <div className="vr-iframe">
                {galleryVideos && galleryVideos.map((item, i) => (
                    <div key={i} className="vr-box">
                        <div className="vr-name" onClick={() => { i == activeIndex ? setActiveIndex(99999999999) : setActiveIndex(i) } }>
                            <span>{item.name ? item.name : "Nowy"}</span>
                        </div>
                        {i == activeIndex &&
                            <VideoRepeater images={media.filter(item => item.type == "image")} setRedux={(data) => addToRedux(data)} data={item} close={() => setActiveIndex(99999999999)} number={i} showNewInputs={galleryVideos} setNewInputs={ (newObject) => setGalleryVideos(newObject) } />
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LibraryVideos;