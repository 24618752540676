import React, { useState, useEffect } from 'react'
import QuizRepeater from './../components/QuizRepeater';
import { useSelector, useDispatch } from 'react-redux'

const Quiz = () => {
    const dispatch = useDispatch();
    let [ media, setMedia ] = useState();
    let quizRedux = useSelector(state => state.quiz);
    let mediaRedux = useSelector(state => state.media);
    let [ questions, setQuestions ] = useState('');
    let [ activeQuestion, setActiveQuestion ] = useState(99999999999);
    let [ activeGroup, setActiveGroup ] = useState(99999999999);

    useEffect(() => {
        setQuestions(quizRedux);
    }, [quizRedux])

    useEffect(() => {
        setMedia(mediaRedux);
    }, [mediaRedux])


    const addNewVr = (index) => {
        setActiveGroup(99999999999)
        setActiveQuestion(99999999999)
        var newQuestions = [];
        
        questions && questions.map((item, i) => {
            if(i == index) {
                let newItem = item;
                let newItemQuestion = item.questions;
                newItem.questions = [""];
                newItemQuestion && newItemQuestion.map(item => newItem.questions.push(item))
                newQuestions.push(newItem);
            }else{
                newQuestions.push(item);
            }
        })
        setQuestions(newQuestions);
    }

    const addToRedux = (data) => {
        dispatch({ type: 'QUIZ', value: data })
    }

    const active = (indexGroup, indexQuestion) => {
        if(indexGroup == activeGroup && indexQuestion == activeQuestion)  {
            setActiveGroup(99999999999)
            setActiveQuestion(99999999999)
        }else{
            setActiveGroup(indexGroup)
            setActiveQuestion(indexQuestion)
        }
    }

    return (
        <div className="library-vr quiz">
            {questions && questions.map((questionGroup, index) => (
                <div key={index}>
                    <div className="actions">
                        <button type="button" className={"btn"} onClick={ () => addNewVr(index) }>Dodaj</button> 
                    </div>
                    <div className="name">
                        <h2>{questionGroup.text}</h2>
                    </div>
                    <div className="vr-iframe">
                        {questionGroup && questionGroup.questions && questionGroup.questions.map((item, i) => (
                            <div key={i} className="vr-box">
                                <div className="vr-name" onClick={() => active(index, i) }>
                                    <span>{item.question ? item.question : "Nowe pytanie"}</span>
                                </div>
                                {i == activeQuestion && index == activeGroup &&
                                    <QuizRepeater indexGroup={index} indexQuestion={i} groupLevel={questionGroup.value} setRedux={(data) => addToRedux(data)} data={item} close={() => setActiveQuestion(99999999999)} showNewInputs={questions} setNewInputs={ (newObject) => setQuestions(newObject) } media={media} />
                                }
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Quiz;