import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import {ProtectedRoute} from './components/protected.route';
import LoginView from './components/login-view/LoginView';
import Dashboard from './components/dashboard/DashboardView';

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Route path="/login" component={ LoginView } props={this.props} />
          <ProtectedRoute path="/dashboard" component={ Dashboard } props={this.props} />
        </Router>
      </div>
    );
  }
}


