// PROPSY: 
// // title="Tytuł" 
// // data="1" 
// // icon="ikona_material"
// // desc={ true }
// // column="4" 
// // descIcon="ikona_material" 
// // descTitle="tytuł " 
// // descLink="Link"/>

import React from 'react';
import { Link } from 'react-router-dom';

const CardStats = ( props ) => ( 
    <div className={`col-${ props.column }`}>
        <div className="card card-stats ">
            <div className="card-header">
                <i className="material-icons card-over">{ props.icon }</i>
                <p>
                    { props.title }
                    { props.data && 
                        <strong>{ props.data }</strong>
                    }
                </p>
            </div>
            { props.desc && 
                <div className="card-dest">
                    <i className="material-icons"> { props.descIcon } </i>
                    <Link to={ props.descLink }> { props.descTitle } </Link>
                </div>
            }
        </div>
    </div>
);

export default CardStats;
