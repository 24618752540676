import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Input from './../components/Input'
import countResultTable from './../../../functions/validations/countResultTable';
import emailValidation from './../../../functions/validations/emailValidation';
import stringValidation from './../../../functions/validations/stringValidation';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';

const User = ({match}) => {
    let [ load, setLoad ] = useState(0);
    let [ login, setLogin ] = useState();
    let [ email, setEmail ] = useState();
    let [ name, setName ] = useState();
    let [ surname, setSurname ] = useState();
    let [ password, setPassword ] = useState();
    let [ errorLogin, setErrorLogin ] = useState(0);
    let [ errorEmail, setErrorEmail ] = useState(0);
    let [ errorPassword, setErrorPassword ] = useState(0);

    useEffect(() => {
        if(load == 0) {
            generatePassword();
            setLoad(1);
        }
    }, [load])

    const generatePassword = () => {
        var length = 16,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        setPassword(retVal);
    }

    const clearErrors = () => {
        setErrorEmail(0);
        setErrorLogin(0);
        setErrorPassword(0);
    }

    const createUser = async() => {
        clearErrors();
        let errors = 0;
        // login
        if(login && login.length > 0 ) {
            var dataLogin = {
                stringName: 'Login',
                checkString: login,
                minLength: 6,
                maxLength: 20,
            }
            const loginStringResult = stringValidation(dataLogin);
    
            const checkCountRecords = {
                stringName: 'Login',
                tableName: 'users',
                tableField: 'login',
                checkString: login,
            }
    
            await countResultTable(checkCountRecords).then(data => {
                if(data.length > 0) {
                    setErrorLogin(1);
                    errors = 1;
                }
            })
    
            if ( loginStringResult && loginStringResult.length > 0 ) {
                setErrorLogin(1);
                errors = 1;
            }
        }else{
            setErrorLogin(1);
            errors = 1;
        }

        // email
        if(email && email.length > 0) {
            var dataEmail = {
                emailName: 'Email',
                checkEmail: email,
            }
            const emailCheck = emailValidation(dataEmail);
    
            const checkCountEmail = {
                stringName: 'E-mail',
                tableName: 'users',
                tableField: 'email',
                checkString: email,
            }
    
            await countResultTable(checkCountEmail).then(data => {
                if(data.length > 0) {
                    setErrorEmail(1);
                    errors = 1;
                }
            })
    
            if ( emailCheck && emailCheck.length > 0 ) {
                setErrorEmail(1);
                errors = 1;
            }
        }else{
            setErrorEmail(1);
            errors = 1;
        }

        if(password && password.length > 0) {
            
        }else{
            setErrorPassword(1);
            errors = 1;
        }

        if(errors === 0) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/newAdmin`,
                data: {
                    userID: localStorage.getItem('userId'),
                    token: localStorage.getItem('token'),
                    login: login,
                    email: email,
                    name: name,
                    surname: surname,
                    password: password,
                    linkPage: window.location.origin,
                    subject: 'Nowe konto administratora',
                    from: 'admin@norddigital.com',
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == "success") {
                    alert("Dodano nowego administratora")
                    generatePassword();
                    setLogin('');
                    setEmail('');
                    setName();
                    setSurname('');
                }else{
                    alert("Coś poszło nie tak, prosimy spróbować poniwnie później")
                }

            }).catch(e => {
                alert("Coś poszło nie tak, prosimy spróbować poniwnie później")
            })
        }
    }
    return (
        <>  
            <div className="section-back">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to="/dashboard/users">Powrót</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card card-data">
                            <div className="card-header">
                                <div className="card-title card-over">
                                    <h5>Dodaj nowego administratora: </h5>
                                    <p>Uzupełnij dane</p>
                                </div>
                            </div>
                            <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-sm-9">
                                        <div className="row">
                                            <Input name="login"
                                                   label="Login"
                                                   type="text" 
                                                   value={ login }
                                                   disabled={ false } 
                                                   handle={ (val) => setLogin(val) } 
                                                   error={errorLogin}
                                                   column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <Input name="email"
                                                   label="Adres e-mail"
                                                   type="email" 
                                                   value={ email }
                                                   disabled={ false } 
                                                   handle={ (val) => setEmail(val) } 
                                                   error={errorEmail}
                                                   column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <Input name="name"
                                                   label="Imię"
                                                   type="text" 
                                                   value={ name }
                                                   disabled={ false } 
                                                   handle={ (val) => setName(val) } 
                                                   column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <Input name="surname"
                                                   label="Nazwisko"
                                                   type="text" 
                                                   value={ surname }
                                                   disabled={ false } 
                                                   handle={ (val) => setSurname(val) } 
                                                   column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <Input name="password"
                                                   label="Hasło"
                                                   type="text" 
                                                   value={ password }
                                                   disabled={ false } 
                                                   handle={ (val) => setPassword(val) } 
                                                   error={errorPassword}
                                                   column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="btn" onClick={ () => createUser() } > Utwórz </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>        
    );
}

export default User;
