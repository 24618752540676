import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import LibraryPopup from './../components/LibraryPopup';
import Pagination from './../components/Pagination';

import { useSelector, useDispatch } from 'react-redux'

const Library = () => {
    let [ fileName, setFileName ] = useState('');
    let [ selectedFile, setSelectedFile] = useState(null);
    let [ openIndex, setOpenIndex ] = useState(999999);
    let [ disableBtn, setDisableBtn ] = useState(false);
    let [ gallery, setGallery ] = useState([]);
    let [ lastPage, setLastPage ] = useState(1);
    let galleryRedux = useSelector(state => state.gallery);
    let lastPageRedux = useSelector(state => state.lastPageGallery);
    let [ page, setPage ] = useState(1);
    let [ search, setSearch ] = useState('');
    let [ visiblePagination, setVisiblePagination ] = useState(true);
    let [ status, setStatus ] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        getGallery(null);
    }, [page])

    useEffect(() => {
        setGallery(galleryRedux);
    }, [galleryRedux])

    useEffect(() => {
        setLastPage(lastPageRedux);
    }, [lastPageRedux])

    const getGallery = (mediaFetch) => {
        setStatus(false);
        axios({
            method: 'post',
            url: `${ApiUrl()}/galleryItems`,
            data: {
                token: localStorage.getItem('token'),
                page: page,
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'GALLERY_2D', value: res.data })
            dispatch({ type: 'LAST_PAGE_GALLERY', value: res.meta.last_page })
            setStatus(true)
        })

        if(mediaFetch) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/media`,
                data: {
                    token: localStorage.getItem('token')
                },
            }).then(response => {
                let res = JSON.parse(response.request.response);
                dispatch({ type: 'MEDIA', value: res })
            })
        }
    }

    const onFileChange = event => { 
        setSelectedFile(event.target.files[0]);
    }; 

    const onFileUpload = () => { 
        setDisableBtn(true);
        var errors = false;

        if(!fileName || fileName.length == 0) {
            alert('Należy wprowadzić nazwę zdjęcia');
            errors = true;
        }

        if(!selectedFile) {
            alert('Należy wgrać zdjęcie');
            errors = true;
        }

        if(errors) {
            setDisableBtn(false);
        }

        if(!errors) {
            const formData = new FormData(); 
            formData.append("file", selectedFile); 
            formData.append("fileName", fileName);
            formData.append('token', localStorage.getItem('token'));
            formData.append('userID', localStorage.getItem('userId'));
            axios({
                method: 'post',
                url: `${ApiUrl()}/newImageLibrary`,
                config: { headers: {'Content-Type': 'multipart/form-data' }},
                data: formData,
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status) {
                    alert("Dodano nowe zdjęcie")
                    setFileName('');
                    setSelectedFile(null);
                    if(typeof window !== "undefined") {
                        document.querySelectorAll('.custom-pagination > li')[page].classList.remove('selected');
                        document.querySelectorAll('.custom-pagination > li')[1].classList.add('selected');
                    }
                    setPage(1);
                    if(page == 1) {
                        getGallery(true);
                    }
                    setDisableBtn(false);
                }else{
                    alert("Coś poszło nie tak, prosimy spróbować poniwnie później")
                    setDisableBtn(false);
                }

            }).catch(e => {
                alert("Coś poszło nie tak, prosimy spróbować poniwnie później")
            })
        }
    }; 

    const fileData = () => { 
        if (selectedFile) { 
          return ( 
            <>
                <span>Nazwa pliku: {selectedFile.name}</span>
            </>
          ); 
        }
    }; 

    const searchAPI = () => {
        if(search.length == 0) {
            setPage(1);
            setVisiblePagination(true);
            getGallery();
        }else{
            axios({
                method: 'post',
                url: `${ApiUrl()}/searchGallery2d`,
                data: {
                    token: localStorage.getItem('token'),
                    string: search,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                dispatch({ type: 'GALLERY_2D', value: res })
                dispatch({ type: 'LAST_PAGE_GALLERY', value: 1 })
                setVisiblePagination(false);
            })
        }
    }

    return (
        <div className="library">
            <div className="upload-place">
                {/* <form class="box" method="post" action="" enctype="multipart/form-data">
                    <img src={DragDrop} />
                    <input class="box__file" type="file" name="files[]" id="file" />
                    <label for="file">Rzuć zdjęcie</label>
                </form> */}
                <div className="actions">
                    <input type="file" onChange={(e) => onFileChange(e)} id="upload" hidden /> 
                    <label htmlFor="upload">Wybierz zdjęcie</label>
                    <input type="text" className="imageName" placeholder="nazwa zdjęcia ( nie pliku )" onInput={(e) => setFileName(e.target.value)} value={fileName} />
                    <button type="button" className={ disableBtn ? "btn disable" : "btn"} onClick={() => !disableBtn ? onFileUpload() : null}>Wgraj</button> 
                </div>
                <div className="information">
                    {fileData()} 
                </div>
            </div>
            <div className="table">
                <div className="search-bar">
                    <div class="searchBox">
                        <input class="searchInput"type="text" name="" placeholder="wpisz szukane słowo" onChange={(e) => setSearch(e.target.value)} value={search} />
                        <button class="searchButton" onClick={() => searchAPI('szukam')}>
                            <i class="material-icons">search</i>
                        </button>
                    </div>
                </div>
                <div className="images-wrapper">
                    {gallery && gallery.map((item, index) => (
                        <>
                        <div key={index} className="image-box" onClick={ () => setOpenIndex(index) }>
                            <img src={item.sizes.small} alt={item.alt} />
                        </div>
                        {status &&
                            <LibraryPopup page={page} gallery={gallery} index={index} data={item} openIndex={openIndex} setIndex={() => setOpenIndex(999999)} />
                        }
                        </>
                    ))}
                </div>
            </div>
            {visiblePagination &&
                <Pagination page={page} setPage={(page) => setPage(page)} lastPage={lastPage} />
            }
        </div>
    );
}

export default Library;