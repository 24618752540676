import React, { useState } from 'react'

const Select = ( props ) => {
    return (
        <div className={ `col-${ props.column } select-wrapper` } >
            <div className="form-group bmd-form-group">
                <label className={props.error ? "bmd-label-floating error" : "bmd-label-floating"} htmlFor={ props.name } > { props.label } </label>
                <select className="form-control" name={ props.name } onInput={ (e) => props.handle(e.target.value) }>
                    {props.data && props.data.map((item, index) => (
                        <option selected={ item.value == props.value} value={item.value} key={index}>{item.text}</option>
                    ))}
                </select>
            </div>
        </div>   
    );
}

export default Select;
