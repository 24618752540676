import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Input from './../components/Input'
import Textarea from './../components/Textarea';
import SelectItems from './../components/SelectItems';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'
import { gallery } from '../../../reducers/onStart';

const GallerySets = (props) => {
    let [ load, setLoad ] = useState(false);
    let mediaRedux = useSelector(state => state.media);
    let gallerySetsRedux = useSelector(state => state.gallerySets);
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ galleryImages, setGalleryImages ] = useState('');
    let [ galleryVideos, setGalleryVideos ] = useState('');
    let [ galleryColoringBooks, setGalleryColoringBooks ] = useState('');
    let [ galleryColoringBooksMobile, setGalleryColoringBooksMobile ] = useState('');
    let [ gallerySliderHome, setGallerySliderHome ] = useState('');
    let [ mediaImages, setMediaImages ] = useState([]);
    let [ mediaVideos, setMediaVideos ] = useState([]);
    let [ mediaSvg, setMediaSvg ] = useState([]);

    useEffect(() => {
        if(!load) {
            if(mediaRedux && mediaRedux.length > 0 && gallerySetsRedux && gallerySetsRedux.length > 0) {
                createMediaItems();
                setLoad(true)
            }
        }
    }, [mediaRedux, gallerySetsRedux, load])

    const createMediaItems = () => {
        let images = [];
        let videos = [];
        let svg = [];
        let loadGalleryImages = [];
        let loadGalleryVideos = [];
        let loadGalleryColoringBooks = [];
        let loadGalleryColoringBooksMobile = [];
        let loadGallerySliderHome = [];
        mediaRedux && mediaRedux.map(galleryItem => {
            if(galleryItem.type == "svg") {
                svg = [...svg, galleryItem];
            }else if(galleryItem.type == "mp4") {
                videos = [...videos, galleryItem];
            }else if(galleryItem.type == "image") {
                images = [...images, galleryItem];
            }
        })

        images && images.map(galleryItem => {
            gallerySetsRedux && gallerySetsRedux.map(sets => {
                if(sets.nameSet == "gallery_images") {
                    if(sets.gallery) {
                        let galleryItems = (sets.gallery).split(";");
                        if(galleryItems && galleryItems.length > 0) {
                            galleryItems.map(media => {
                                if(media == galleryItem.id) {
                                    loadGalleryImages = [...loadGalleryImages, galleryItem]
                                }
                            })
                        }
                    }
                }
            })
        })

        videos && videos.map(galleryItem => {
            gallerySetsRedux && gallerySetsRedux.map(sets => {
                if(sets.nameSet == "gallery_videos") {
                    if(sets.gallery) {
                        let galleryItems = (sets.gallery).split(";");
                        if(galleryItems && galleryItems.length > 0) {
                            galleryItems.map(media => {
                                if(media == galleryItem.id) {
                                    loadGalleryVideos = [...loadGalleryVideos, galleryItem]
                                }
                            })
                        }
                    }
                }
            })
        })

        svg && svg.map(galleryItem => {
            gallerySetsRedux && gallerySetsRedux.map(sets => {
                if(sets.nameSet == "gallery_coloring_book") {
                    if(sets.gallery) {
                        let galleryItems = (sets.gallery).split(";");
                        if(galleryItems && galleryItems.length > 0) {
                            galleryItems.map(media => {
                                if(media == galleryItem.id) {
                                    loadGalleryColoringBooks = [...loadGalleryColoringBooks, galleryItem]
                                }
                            })
                        }
                    }
                }
            })
        })

        svg && svg.map(galleryItem => {
            gallerySetsRedux && gallerySetsRedux.map(sets => {
                if(sets.nameSet == "gallery_coloring_book_mobile") {
                    if(sets.gallery) {
                        let galleryItems = (sets.gallery).split(";");
                        if(galleryItems && galleryItems.length > 0) {
                            galleryItems.map(media => {
                                if(media == galleryItem.id) {
                                    loadGalleryColoringBooksMobile = [...loadGalleryColoringBooksMobile, galleryItem]
                                }
                            })
                        }
                    }
                }
            })
        })

        images && images.map(galleryItem => {
            gallerySetsRedux && gallerySetsRedux.map(sets => {
                if(sets.nameSet == "gallery_slider_home") {
                    if(sets.gallery) {
                        let galleryItems = (sets.gallery).split(";");
                        if(galleryItems && galleryItems.length > 0) {
                            galleryItems.map(media => {
                                if(media == galleryItem.id) {
                                    loadGallerySliderHome = [...loadGallerySliderHome, galleryItem]
                                }
                            })
                        }
                    }
                }
            })
        })

        setGalleryImages(loadGalleryImages);
        setGalleryVideos(loadGalleryVideos);
        setGalleryColoringBooks(loadGalleryColoringBooks);
        setGalleryColoringBooksMobile(loadGalleryColoringBooksMobile);
        setGallerySliderHome(loadGallerySliderHome);

        setMediaImages(images);
        setMediaVideos(videos);
        setMediaSvg(svg);
    }

    const save = (nameSet) => {
        setBlockedBtn(true);
        axios({
            method: 'post',
            url: `${ApiUrl()}/saveGallerySets`,
            data: {
                token: localStorage.getItem('token'),
                nameSet: nameSet,
                galleryImages: galleryImages,
                galleryVideos: galleryVideos,
                galleryColoringBooks: galleryColoringBooks,
                galleryColoringBooksMobile: galleryColoringBooksMobile,
                gallerySliderHome: gallerySliderHome,
                userID: localStorage.getItem('userId'),
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status = "success") {
                setBlockedBtn(false);
                alert("Aktualizacja przebiegła pomyślnie")
            }else{
                setBlockedBtn(false);
                alert("Coś poszło nie tak, prosimy spróbować poźniej")
            }
        })
    }


    return (
        <>  
            <div className="section-back">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to="/dashboard/pages">Powrót</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pages">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card card-data">
                            <div className="card-header">
                                <div className="card-title card-over">
                                    <h5>Dodaj nową stronę: </h5>
                                    <p>Uzupełnij dane</p>
                                </div>
                            </div>
                            <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-sm-9">
                                        <div className="row">
                                            <SelectItems name="lng"
                                                label="Galeria zdjęć"
                                                data={ mediaImages } 
                                                value={ galleryImages }
                                                handle={ (val) => setGalleryImages(val) } 
                                                column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className={blockedBtn ? "btn disable" : "btn"} onClick={ () => { !blockedBtn && save('gallery_images') } }>Zapisz galerie</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <SelectItems name="lng"
                                                label="Galeria filmików"
                                                data={ mediaVideos } 
                                                value={ galleryVideos }
                                                handle={ (val) => setGalleryVideos(val) } 
                                                column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className={blockedBtn ? "btn disable" : "btn"} onClick={ () => { !blockedBtn && save('gallery_videos') } }>Zapisz galerie video</div>
                                            </div>
                                        </div>
                                        <h3 style={{margin: "15px 0"}}>Przykład: pierwsze zdjęcie desktop jest odpowiednikiem pierwszego zdjęcia mobile (taka sama kolejność !!)</h3>
                                        <div className="row">
                                            <SelectItems name="lng"
                                                label="Kolorowanki - desktop"
                                                data={ mediaSvg } 
                                                value={ galleryColoringBooks }
                                                handle={ (val) => setGalleryColoringBooks(val) } 
                                                column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                            <div className={blockedBtn ? "btn disable" : "btn"} onClick={ () => { !blockedBtn && save('gallery_coloring_book') } }>Zapisz Kolorowanki</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <SelectItems name="lng"
                                                label="Kolorowanki - mobile"
                                                data={ mediaSvg } 
                                                value={ galleryColoringBooksMobile }
                                                handle={ (val) => setGalleryColoringBooksMobile(val) } 
                                                column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                            <div className={blockedBtn ? "btn disable" : "btn"} onClick={ () => { !blockedBtn && save('gallery_coloring_book_mobile') } }>Zapisz Kolorowanki mobilne</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <SelectItems name="lng"
                                                label="Slider - strona główna"
                                                data={ mediaImages } 
                                                value={ gallerySliderHome }
                                                handle={ (val) => setGallerySliderHome(val) } 
                                                column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                            <div className={blockedBtn ? "btn disable" : "btn"} onClick={ () => { !blockedBtn && save('gallery_slider_home') } }>Zapisz Slider</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>        
    );
}

export default GallerySets;
