import React, { useState, useEffect } from 'react'
import Input from './../components/Input';
import Select from './../components/Select';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import SelectItems from './SelectItems';
import { useSelector, useDispatch } from 'react-redux'

const VideoRepeater = ( props ) => {
    let [ load, setLoad ] = useState(0);
    let [ name, setName ] = useState('');
    let [ option, setOption ] = useState('mp3');
    let [ yt, setYt ] = useState('');
    let [ id, setId ] = useState(0);
    let [ uploadFile, setUploadFile ] = useState([])
    let [ ytUrlValid, setYtUrlValid ] = useState(false);
    let [ audioTune, setAudioTune ] = useState(false);
    let [ updateSrc, setUpdateSrc ] = useState(0);
    let [ disableBtn, setDisableBtn ] = useState(false);
    let [ poster, setPoster ] = useState([]);

    useEffect(() => {
        
        if(load === 0) {
            if(props.data && props.data.name) {
                setName(props.data.name);
                setOption(props.data.type);
                setId(props.data.id);
                if(props.data.type == "mp4") {
                    setYt(props.data.src);
                }else{
                    if(props.data.src) {
                        setAudioTune(new Audio(props.data.src));
                    }
                }
                if(props.data.poster) {
                    createPoster();
                }
            }
            setLoad(1);
        }

    }, [load])

    const createPoster = () => {
        var loadGalleryImages = [];
        props.images && props.images.map(galleryItem => {
            if(props.data.poster) {
                if(props.data.poster.id == galleryItem.id) {
                    loadGalleryImages = [...loadGalleryImages, galleryItem]
                }
            }
        })
        setPoster(loadGalleryImages);
    }

    useEffect(() => {
        if(audioTune) {
            audioTune.load();
        }
    }, [audioTune])

    const playSound = () => {
        audioTune.play();
    }
       
    // pause audio sound
    const pauseSound = () => {
        audioTune.pause();
    }
    
    // reset audio sound
    const resetSound = () => {
        audioTune.pause();
        audioTune.currentTime = 0;
    }

    const createObject = () => {
        if(!disableBtn) {
            setDisableBtn(true);
            var errors = false;

            if(!name || name.length == 0) {
                alert('Należy wprowadzić nazwę');
                errors = true;
            }

            if(option == "mp4") {
                if(!yt || yt.length == 0) {
                    alert('Należy wprowadzić link do youtube');
                    errors = true;
                }
                if(poster.length == 0) {
                    alert('Należy wybrać poster');
                    errors = true;
                }
            }else{
                if(!audioTune || audioTune.length == 0) {
                    alert('Należy wprowadzić mp3');
                    errors = true;
                }
            }


            if(errors) {
                setDisableBtn(false);
            }
            if(!errors) {
                var formData = new FormData();
                formData.append('token', localStorage.getItem('token'))
                formData.append('id', id)
                formData.append('name', name)
                formData.append('type', option)
                formData.append('uploadFile', uploadFile)
                formData.append('yt', yt)
                formData.append('updateSrc', updateSrc)
                formData.append('poster', poster.length > 0 ? poster[0].id : 0)
                formData.append('userID', localStorage.getItem('userId'))
                axios({
                    method: 'post',
                    url: `${ApiUrl()}/videoChange`,
                    config: { headers: {'Content-Type': 'multipart/form-data' }},
                    data: formData
                }).then(response => {
                    let res = JSON.parse(response.request.response);
                    var newGalleryVideo = props.showNewInputs;
                    newGalleryVideo[props.number] = res;
                    props.setRedux(newGalleryVideo);
                    alert('Zapisano');
                })
            }
        }
    }

    const removeVideo = (Id) => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/removeVideo`,
            data: {
                token: localStorage.getItem('token'),
                id: Id,
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status == "success") {
                let newGalleryVideo = props.showNewInputs;
                newGalleryVideo = newGalleryVideo.filter((item, i) => i !== props.number)
                props.setRedux(newGalleryVideo);
                props.close();
            }
        })
    }

    let optionSelect = [
        {
            value: "mp3",
            text: "mp3"
        }, 
        {
            value: "mp4",
            text: "mp4"
        }
    ]

    useEffect(() => {
        if(option == "mp4") {
            var url = yt;
            if (url != undefined || url != '') {
                var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                var match = url.match(regExp);
                if (match && match[2].length == 11) {
                    setYtUrlValid('https://www.youtube.com/embed/' + match[2] + '?autoplay=1');
                }else{
                    setYtUrlValid(false)
                }
            }
        }
    }, [yt])



    const uploadMp3 = (e) => {
        var sourceAux = URL.createObjectURL(e.target.files[0]);
        setAudioTune(new Audio(sourceAux));
        setUpdateSrc(1)
    }

    return (
        <div className="video-repeater-wrapper">
            <div className="remove-vr">
                {id != 0 && 
                    <span onClick={() => removeVideo(id)}>Usuń</span>
                }
            </div>
            <div className="data">
                <div className="inputs">
                    <div className="row">
                        <Input name="name"
                            label="Nazwa"
                            type="text" 
                            value={ name }
                            disabled={ false } 
                            handle={ (val) => { setName(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Select name="option"
                            label="Wybierz"
                            data={ optionSelect } 
                            value={ option }
                            handle={ (val) => { setOption(val) } } 
                            column="xs-12" />
                    </div>
                    {option == "mp4" &&
                    <>
                        <div className="row">
                            <Input name="yt"
                                label="YT Link"
                                type="text" 
                                value={ yt }
                                disabled={ false } 
                                handle={ (val) => { setYt(val); setUpdateSrc(1) } } 
                                error={null}
                                column="xs-12" />
                        </div>
                        <div className="row">
                            <SelectItems name="poster"
                                label="Poster"
                                data={ props.images } 
                                value={ poster }
                                handle={ (val) => setPoster(val) } 
                                limit={1}
                                column="xs-12" />
                        </div>
                    </>
                    }
                    {option == "mp3" &&
                        <div className="row remove-row">
                            <div className="input-file-container size-btn">  
                                <input className="input-file" id="my-file" type="file" onChange={ (e) => { setUploadFile(e.target.files[0]); uploadMp3(e) } } />
                                <label tabIndex="0" htmlFor="my-file" className="input-file-trigger">Wybierz plik</label>
                            </div>
                        </div>
                    }
                    <div className="submit">
                        {option == "mp3" && audioTune &&
                            <input type="button" value="Zapisz" className="btn" onClick={() => createObject()} />
                        }
                        {option == "mp4" && ytUrlValid &&
                            <input type="button" value="Zapisz" className="btn" onClick={() => createObject()} />
                        }
                    </div>
                </div>
                <div className="preview">
                    {ytUrlValid && option == "mp4" &&
                        <div className="video" dangerouslySetInnerHTML={ {__html: `<iframe src=${ytUrlValid} frameborder="0"></iframe>`} } />
                    }
                    {audioTune && option == "mp3" &&
                        <div className="sounds">
                            <input type="button" className="btn btn-primary mr-2" value="Play" onClick={playSound}></input>
                            <input type="button" className="btn btn-warning mr-2" value="Pause" onClick={pauseSound}></input>
                            <input type="button" className="btn btn-danger mr-2" value="Od początku" onClick={resetSound}></input>
                        </div>
                    }
                </div>
            </div>
        </div>  
    );
}

export default VideoRepeater;
