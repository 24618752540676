import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Input from '../partials/Input';
import Message from '../partials/Message';
import getString from '../../../strings/getString';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import actualDate from './../../../functions/functions/actualDate';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorEmail: [],
            errorPassword: [],
            errorMain: [],
            successMain: [],

            email: '',
            password: '',
        }
    }

    clearRegisterErrors() {
        this.setState({
            errorEmail: [],
            errorPassword: [],
            errorMain: [],
            successMain: [],
        })
    }

    async handleSubmit(e) {
        e.preventDefault();
        await this.clearRegisterErrors();
        axios({
            method: 'post',
            url: `${ApiUrl()}/login`,
            data: {
                password: this.state.password,
                email: this.state.email,
            }
        }).then(response => {
            // this.props.history.push("/dashboard");
            let res = JSON.parse(response.request.response);
            if(res.status === 1) {
                localStorage.setItem('token', res.token);
                localStorage.setItem('userId', res.userId);
                localStorage.setItem('sessionDate', actualDate());
                window.location.reload();
            }else{
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('sessionDate');
                this.setState({
                    errorMain: [...this.state.errorMain, getString('errorLoginPassword')]
                })
            }
        }).catch(e => {
            this.setState({
                errorMain: [...this.state.errorMain, getString('errorLoginPassword')]
            })
        })
    }

    registerAlert() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let register = params.get('register');
        if(register == 'success') {
            this.setState({
                successMain: [...this.state.successMain, getString('registerSuccess')]
            })
        }
    }

    componentDidMount() {
        this.registerAlert();
    }
    
    render() {
        return (
            <>
            <Message info={this.state.errorMain} class="error" />
            <Message info={this.state.successMain} class="success" />

            <h1>Zaloguj się</h1>

            <form onSubmit={this.handleSubmit.bind(this)}>
                <Input 
                    name="email"
                    text="Wpisz adres email"
                    type="email"
                    placeholder="E-mail"
                    required={true}
                    errors={this.state.errorEmail}
                    handle={ val => this.setState({ email: val })} />

                <Input 
                    name="password"
                    text="Wpisz hasło"
                    type="password"
                    placeholder="Password"
                    required={true}
                    errors={this.state.errorPassword}
                    handle={ val => this.setState({ password: val })} />

                <button type="submit">Zaloguj</button>
            </form>

            <div className="navigation">

                {/* <small>Nie masz konta? <Link to="/login/new-user">załóż konto</Link></small> */}
                <small>Zapomniałeś hasła? <Link to="/login/send-reset-password">zresetuj hasło</Link></small>

            </div>
            </>
        );
    }
}