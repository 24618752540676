import React, { useEffect, useState } from 'react';
import CardStats from './../components/CardStats';
import Get from './../../../functions/functions/get';
import getString from './../../../strings/getString';

const SiteInfo = () => {
    let [ load, setLoad ] = useState(0);
    let [ status, setStatus ] = useState('Brak informacji');
    useEffect(() => {
        if(load === 0) {
            checkStatus();
            setLoad(1);
        }
    }, [load])

    const checkStatus = () => {
        if(Get("status")) {
            let info = getString(Get("status")) !== "Not found" ? getString(Get("status")) : "Brak informacji";
            setStatus(info);
        }
    }
    return (
        <div className="row">
            <CardStats  data={status}
                        icon="info"
                        desc={ true }
                        column="sm-8" 
                        />
        </div>
    );
}

export default SiteInfo;
