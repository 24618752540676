import React, { useState, useEffect } from 'react'
import Input from './../components/Input';
import Select from './../components/Select';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';

const DataRepeater = ( props ) => {
    let [ load, setLoad ] = useState(0);
    let [ name, setName ] = useState('');
    let [ option, setOption ] = useState('mapa');
    let [ optionVr, setOptionVr ] = useState('');
    let [ id, setId ] = useState(0);
    let [ positionTop, setPositionTop ] = useState(0);
    let [ positionLeft, setPositionLeft ] = useState(0);
    let [ disableBtn, setDisableBtn ] = useState(false);

    useEffect(() => {
        
        if(load === 0) {
            if(props.data && props.data.name) {
                setName(props.data.name);
                setOption(props.data.type);
                setId(props.data.id);
                setOptionVr(props.data.vr_id);
                setPositionTop(props.data.position_top);
                setPositionLeft(props.data.position_left);
            }else{
                if(props.vrOptions && props.vrOptions[0]) {
                    setOptionVr(props.vrOptions[0].value)
                }
            }
            setLoad(1);
        }

    }, [load])

    const createObject = () => {
        if(!disableBtn) {
            setDisableBtn(true);
            var errors = false;

            if(!name || name.length == 0) {
                alert('Należy wprowadzić nazwę');
                errors = true;
            }

            if(!positionLeft || positionLeft.length == 0) {
                alert('Należy wprowadzić pozycję');
                errors = true;
            }

            if(!positionTop || positionTop.length == 0) {
                alert('Należy wprowadzić pozycję');
                errors = true;
            }

            if(errors) {
                setDisableBtn(false);
            }
            console.log(optionVr)
            if(!errors) {
                axios({
                    method: 'post',
                    url: `${ApiUrl()}/mapPathChange`,
                    data: {
                        token: localStorage.getItem('token'),
                        id: id,
                        userID: localStorage.getItem('userId'),
                        name: name,
                        type: option,
                        vrID: optionVr,
                        positionTop: positionTop,
                        positionLeft: positionLeft 
                    }
                }).then(response => {
                    let res = JSON.parse(response.request.response);
                    var newMapPath = props.showNewInputs;
                    newMapPath[props.number] = res;
                    props.setRedux(newMapPath);
                    setDisableBtn(false);
                    alert('Zapisano');
                })
            }
        }
    }

    const removeItem = (Id) => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/removeMapPath`,
            data: {
                token: localStorage.getItem('token'),
                id: Id,
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status == "success") {
                var newMapPath = props.showNewInputs;
                newMapPath = newMapPath.filter((item, i) => i !== props.number)
                props.setRedux(newMapPath);
                props.close();
            }
        })
    }

    let optionSelect = [
        {
            value: "mapa",
            text: "mapa"
        }, 
        {
            value: "wyczolkowski",
            text: "wyczolkowski"
        }
    ]
    console.log(props.vrOptions)
    return (
        <div className="video-repeater-wrapper">
            <div className="remove-vr">
                {id != 0 && 
                    <span onClick={() => removeItem(id)}>Usuń</span>
                }
            </div>
            <div className="data">
                <div className="inputs">
                    <div className="row">
                        <Input name="name"
                            label="Nazwa"
                            type="text" 
                            value={ name }
                            disabled={ false } 
                            handle={ (val) => { setName(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Select name="option"
                            label="Wybierz trase"
                            data={ optionSelect } 
                            value={ option }
                            handle={ (val) => { setOption(val) } } 
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Select name="option"
                            label="Jaki VR otwierać"
                            data={ props.vrOptions } 
                            value={ optionVr }
                            handle={ (val) => { setOptionVr(val) } } 
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Input name="position-top"
                            label="Pozycja TOP"
                            type="text" 
                            value={ positionTop }
                            disabled={ false } 
                            handle={ (val) => { setPositionTop(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Input name="position-left"
                            label="Pozycja LEFT"
                            type="text" 
                            value={ positionLeft }
                            disabled={ false } 
                            handle={ (val) => { setPositionLeft(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="submit">
                        <input type="button" value="Zapisz" className="btn" onClick={() => createObject()} />
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default DataRepeater;
