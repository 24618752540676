import React, { useState,useEffect } from 'react';
import { Route } from "react-router-dom";
import '../../scss/main.scss';
import Login from './content/Login';
import Register from './content/Register';
import Logo from '../../img/logo-nd.png';
import SendResetPassword from './content/SendResetPassword';
import ChangePassword from './content/ChangePassword';
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';

let countUsers = 1;
const LoginView = (props) => {
    let [load, setLoad] = useState(0);

    useEffect(() => {
        if (load == 0) {
            ;(async function() {
                await sessionUser();
                await checkCountUser();
                await setLoad(1);
            })()
        }
    },[load]);

    const sessionUser = () => {
        var currentUser = localStorage.getItem('token') ? true : false;
        if (currentUser) {
            props.history.push({ pathname: '/dashboard'})
        }
    }

    const checkCountUser = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/countUsers`,
            headers: { 
                'Access-Control-Allow-Origin' : '*',
            },
        }).then(response => {
            countUsers = response.request.response;
        });
    }

    return (
        <section className="login-wrapper">

            <div className="overlay"></div>

            <img src={Logo} alt="norddigital" />

            <div className="login">
                    <Route path="/login" exact component={Login} />
                    { countUsers == 0 &&
                        <Route path={`${props.match.url}/new-user`} component={Register} />
                    }
                    <Route path={`${props.match.url}/send-reset-password`} component={SendResetPassword} />
                    <Route path={`${props.match.url}/reset-password`} component={ChangePassword} />
            </div>

        </section>
    );
}

export default LoginView;