import React, { useEffect, useState } from 'react'
import PagesTable from '../components/table/PagesTable'
import CardStats from './../components/CardStats'
import axios from 'axios'
import ApiUrl from './../../../services/ApiUrl'

let countUsers;
const Pages = (props) => {

    let [load, setLoad] = useState(0);
    
    useEffect(() => {
        if (load == 0 && !countUsers) {
            ;(async function() {
                await checkCountUser();
                await setLoad(1);
            })()
        }
    },[load]);

    const checkCountUser = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/countPages`,
            headers: { 
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
        }).then(response => {
            countUsers = response.request.response;
        });
    }

    const goToPage = () => {
        props.history.push('/dashboard/pages/new')
    }

    return (
        <>  
            <div className="container-fluid">
                <div className="row">
                    <CardStats title="Wszystkich stron" 
                                data={countUsers} 
                                icon="account_box"
                                desc={ false }
                                column="sm-3" />
                </div>
                <div className="row">
                    <div className="col-sm-3" onClick={ () => goToPage() }>
                        <div className="btn">
                            <i className="material-icons">add</i>
                            Dodaj nową stronę
                        </div>
                    </div>
                </div>
                <div className="row">
                    <PagesTable col="xs-12" This={props}/>
                </div>
            </div>
        </>        
    );
}

export default Pages;
