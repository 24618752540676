import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Input from '../partials/Input';
import Message from '../partials/Message';
import countResultTable from '../../../functions/validations/countResultTable';
import getString from '../../../strings/getString'
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorEmail: [],
            errorMain: [],
            successMain: [],
            email: '',
        }
    }

    clearRegisterErrors() {
        this.setState({
            errorEmail: [],
            errorMain: [],
            successMain: [],
        })
    }

    async handleSubmit(e) {
        e.preventDefault();
        await this.clearRegisterErrors();
        const checkCountEmail = {
            tableName: 'users',
            tableField: 'email',
            checkString: this.state.email,
        }

        await countResultTable(checkCountEmail).then(data => {
            if (data.length > 0) {
                this.setState((prevState) => ({
                    successMain: [...prevState.errorEmail, getString('resetPasswordSend')]
                }))
                this.sendResetEmail();
            }else{
                this.setState((prevState) => ({
                    errorMain: [...prevState.errorEmail, getString('emailNotFound')]
                }))
            }
        })
    }

    sendResetEmail() {
        axios({
            method: 'post',
            url: `${ApiUrl()}/sendEmail`,
            data: {
                email: this.state.email,
                subject: 'Resetowanie hasła',
                from: 'test@wp.pl',
                template: 'resetPassword',
                linkPage: window.location.origin,
            }
        })
        this.setState({
            email: ''
        })
    }
    
    render() {
        return (
            <>
            <Message info={this.state.errorMain} class="error" />
            <Message info={this.state.successMain} class="success" />

            <h1>Wprowadź swój adres e-mail, na który otrzymasz link resetujący hasło</h1>

            <form onSubmit={this.handleSubmit.bind(this)}>
                <Input 
                    name="email"
                    type="email"
                    placeholder="Twój adres e-mail"
                    required={true}
                    errors={this.state.errorEmail}
                    value={this.state.email}
                    handle={ val => this.setState({ email: val })} />

                <button type="submit">Wyślij email resetujący hasło</button>
            </form>

            <div className="navigation">

                <small><Link to="/login">Wróć do logowania</Link></small>

            </div>
            </>
        );
    }
}