import React from 'react';

const Message = (props) => {
    var messages = props.info;
    return (
        <>
            {messages.map((message, index) => (
                <>
                    { message &&
                        <div key={ index }>
                            <span className={props.class} key={ index }> { message } </span>
                        </div>
                    }
                </>
            ))}
        </>
    );
}

export default Message;