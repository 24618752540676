import React, { useEffect } from 'react';
import { Link } from "react-router-dom"
import logo from '../../../img/logo-nd.png';

const Navbar = () => {
    let toggleMenu = () => {
        document.body.classList.toggle('menu-toggle');
    }
    useEffect(() => {
        if (window.matchMedia("(max-width: 576px)").matches) {
            document.body.classList.toggle('menu-toggle');
          } else {
            document.body.classList.remove('menu-toggle');
          }
    })

    return (
        <nav className="menu" id="menu">
            <div className="menu-wrapper">
                <button id="toggle-menu" className="slide-menu" onClick={ () => toggleMenu() }><i className="material-icons">transit_enterexit</i></button>
                <h1>
                    <img src={ logo } alt="Norddigital" />
                    <small>Norddigital CMS</small>
                </h1>
                <ul>
                    <li>
                        <Link to="/dashboard">
                            <i className="material-icons">dashboard</i>
                            <span>Strona główna</span>
                        </Link>
                    </li>
                    
                    <li>
                        <Link to="/dashboard/users">
                            <i className="material-icons">people</i>
                            <span>Użytkownicy</span>
                        </Link>
                        <ul>
                            <li>
                                <Link to="/dashboard/users/new">
                                    <i className="material-icons">add</i>
                                    <span>Nowy</span>
                                </Link> 
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/dashboard/library">
                            <i className="material-icons">slideshow</i>
                            <span>Biblioteka mediów</span>
                        </Link>
                        <ul>
                            <li>
                                <Link to="/dashboard/library-vr">
                                    <i className="material-icons">3d_rotation</i>
                                    <span>Biblioteka VR</span>
                                </Link> 
                            </li>
                            <li>
                                <Link to="/dashboard/library-videos">
                                    <i className="material-icons">4k</i>
                                    <span>Biblioteka VIDEO</span>
                                </Link> 
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/dashboard/pages">
                            <i className="material-icons">pageview</i>
                            <span>Strony</span>
                        </Link>
                        <ul>
                            <li>
                                <Link to="/dashboard/pages/new">
                                    <i className="material-icons">add</i>
                                    <span>Nowa strona</span>
                                </Link> 
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/dashboard/map-path">
                            <i className="material-icons">explore</i>
                            <span>Mapa ze ścieżką</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/quiz">
                            <i className="material-icons">question_answer</i>
                            <span>Quiz</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/gallery-sets">
                            <i className="material-icons">collections</i>
                            <span>Zestawy zdjęć/filmików</span>
                        </Link>
                    </li>
                </ul>
                <a href="http://norddigital.com" className="description" alt="NordDigital" target="_blank">
                    <img src={ logo } />
                    <span> Created by NordDigital</span>
                </a>
            </div>
            <div className="menu-bg"></div>
        </nav>
    );
}

export default Navbar;
