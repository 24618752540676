import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Input from './../components/Input'
import Textarea from './../components/Textarea';
import SelectItems from './../components/SelectItems';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Page = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ name, setName ] = useState('');
    let [ text, setText ] = useState('');
    let [ media, setMedia ] = useState([]);
    let [ mediaTop, setMediaTop ] = useState();
    let [ mediaRight, setMediaRight ] = useState();
    let [ mediaBottom, setMediaBottom ] = useState();
    let [ errorName, setErrorName ] = useState(0);
    let mediaRedux = useSelector(state => state.media);
    let pageID = typeof window !== "undefined" && (window.location.pathname).split("/");pageID = pageID[pageID.length -1]
    
    useEffect(() => {
        if(!load) {
            getPage();
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        setMedia(mediaRedux);
    }, [mediaRedux])

    const getPage = async() => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/getPageByID`,
            data: {
                token: localStorage.getItem('token'),
                pageID: pageID
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setName(res.name);
            setText(res.text);
            setMediaTop(JSON.parse(res.media_top));
            setMediaRight(JSON.parse(res.media_right));
            setMediaBottom(JSON.parse(res.media_bottom));
        })
    }

    const save = () => {
        setBlockedBtn(true);
        setErrorName(0);
        let errors = 0;

        if(name.length == 0) {
            setErrorName(1);
            errors = 1;   
        }

        if(errors) {
            setBlockedBtn(false);
        }

        if(errors === 0) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/savePage`,
                data: {
                    token: localStorage.getItem('token'),
                    name: name,
                    text: text,
                    mediaTop: mediaTop,
                    mediaBottom: mediaBottom,
                    mediaRight: mediaRight,
                    userID: localStorage.getItem('userId'),
                    edit: true,
                    pageID: pageID
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status = "success") {
                    setBlockedBtn(false);
                    alert("Aktualizacja przebiegła pomyślnie")
                }else{
                    setBlockedBtn(false);
                    alert("Coś poszło nie tak, prosimy spróbować poźniej")
                }
            })
        }
    }

    return (
        <>  
            <div className="section-back">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12">
                            <Link to="/dashboard/pages">Powrót</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid pages">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card card-data">
                            <div className="card-header">
                                <div className="card-title card-over">
                                    <h5>Edytuj stronę: </h5>
                                    <p>Uzupełnij dane</p>
                                </div>
                            </div>
                            <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-sm-9">
                                        <div className="row">
                                            <Input name="name"
                                                   label="Nazwa"
                                                   type="text" 
                                                   value={ name }
                                                   disabled={ false } 
                                                   handle={ (val) => setName(val) } 
                                                   error={errorName}
                                                   column="xs-12" />
                                        </div>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={text}
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                setText(data);
                                            } }
                                        />
                                        <div className="row">
                                            <SelectItems name="top"
                                                label="Wybierz media na góre"
                                                data={ media.filter(item => item.type == "mp3") } 
                                                value={ mediaTop }
                                                handle={ (val) => setMediaTop(val) } 
                                                limit={1}
                                                column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <SelectItems name="right"
                                                label="Wybierz media na prawą stronę"
                                                data={ media.filter(item => item.type == "image") } 
                                                value={ mediaRight }
                                                handle={ (val) => setMediaRight(val) } 
                                                column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <SelectItems name="bottom"
                                                label="Wybierz media na dół (galeria)"
                                                data={ media.filter(item => item.type == "image") } 
                                                value={ mediaBottom }
                                                handle={ (val) => setMediaBottom(val) } 
                                                column="xs-12" />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                            <div className={blockedBtn ? "btn disable" : "btn"} onClick={ () => { !blockedBtn && save() } }>Zapisz</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>        
    );
}

export default Page;
