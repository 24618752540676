import React from 'react';
import logout from '../../../functions/user/logout';

const Header = (props) => {
    return (
        <header>
            <nav>
                <ul>
                    <li>
                        Witaj,
                        <span> {props.user && props.user.login} </span>
                    </li>
                    <li>
                        <a onClick={ () => logout(props) } href="#">
                            <i className="material-icons">power_settings_new</i>
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
