import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Input from '../partials/Input';
import Message from '../partials/Message';
import passwordValidation from '../../../functions/validations/passwordValidation';
import getString from '../../../strings/getString';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorPassword: [],
            errorMain: [],
            successMain: [],

            password: '',
            secondPassword: '',
        }
    }

    clearRegisterErrors() {
        this.setState({
            errorPassword: [],
            errorMain: [],
            successMain: [],
        })
    }

    async handleSubmit(e) {
        e.preventDefault();
        await this.clearRegisterErrors();
        var dataPassword = {
            checkPassword_one: this.state.password,
            checkPassword_two: this.state.secondPassword,
            minLength: 6,
            maxLength: 30
        }
        const passwordCheck = passwordValidation(dataPassword);

        if (passwordCheck && passwordCheck.length > 0) {

            passwordCheck.map(el => {
                this.setState({
                    errorPassword: [...this.state.errorPassword, el],
                })
            })

        }

        if (this.state.errorPassword.length === 0){
            let search = window.location.search;
            let url = new URLSearchParams(search);
            let token = url.get('token');
            axios({
                method: 'post',
                url: `${ApiUrl()}/changePassword`,
                data: {
                    password: this.state.password,
                    token: token,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == 1) {
                    this.setState({
                        successMain: [getString('newPasswordSet')],
                        password: '',
                        secondPassword: ''
                    })
                }else{
                    this.setState({
                        errorMain: [getString('problemChangePassword')]
                    })
                }
            }).catch(e => {
                this.setState({
                    errorMain: [getString('problemChangePassword')]
                })
            })
        }
    }
    
    render() {
        return (
            <>
            <Message info={this.state.errorMain} class="error" />
            <Message info={this.state.successMain} class="success" />

            <h1>Wprowadź nowe hasło</h1>

            <form onSubmit={this.handleSubmit.bind(this)}>
                <Input 
                    value={this.state.password}
                    name="password"
                    type="password"
                    placeholder="Twoje nowe hasło"
                    required={true}
                    errors={this.state.errorPassword}
                    handle={ val => this.setState({ password: val })} />

                <Input 
                    value={this.state.secondPassword}
                    name="password-second"
                    type="password"
                    placeholder="Twoje nowe hasło"
                    required={true}
                    errors={this.state.errorPassword}
                    handle={ val => this.setState({ secondPassword: val })} />

                <button type="submit">Ustaw nowe hasło</button>
            </form>

            <div className="navigation">

                <small><Link to="/login">Wróć do logowania</Link></small>

            </div>
            </>
        );
    }
}