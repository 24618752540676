import axios from "axios";
import ApiUrl from "./../../services/ApiUrl";

const logout = async (props) => {

    axios({
        method: 'post',
        url: `${ApiUrl()}/logout`,
        data: {
            userId: localStorage.getItem('userId'),
            token: localStorage.getItem('token'),
        }
    })

    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    if(props) {
        props.history.push({ pathname: '/login' });
    }
}

export default logout;