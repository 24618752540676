const passwordValidation = (props) => {
    var passOne = props.checkPassword_one;
    var passTwo = props.checkPassword_two;
    var minLength = props.minLength;
    var maxLength = props.maxLength;
    var errors = [];
    if(passOne !== passTwo) {
        errors = [...errors, 'Podane hasła muszą być identyczne !'];
    }
    if(passOne.length < minLength || passOne.length > maxLength) {
        errors = [...errors, `Hasło musi mieć minimum ${minLength} znaków i nie więcej niż ${maxLength}.`];
    }

    return errors;   
}

export default passwordValidation;