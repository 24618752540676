import React, { useState } from 'react'
import ReactPaginate from 'react-paginate';

const Pagination = ( props ) => {
    return (
        <>
            {/* {props.galleryCountItems > props.page && ( */}
                <div className="pagination-wrapper">
                    <div className="pagination">
                        <ReactPaginate 
                        pageCount={ props.lastPage  } 
                        pageRangeDisplayed={ 4 }
                        marginPagesDisplayed={ 1 }
                        nextLabel=""
                        previousLabel=""
                        containerClassName="custom-pagination"
                        onPageChange={ (index) => { 
                            props.setPage(index.selected + 1);
                            }} 
                            />
                    </div>
                </div> 
            {/* )} */}
        </>
    );
}

export default Pagination;
