const stringValidation = (props) => {
    var name = props.stringName;
    var string = props.checkString;
    var minLength = props.minLength;
    var maxLength = props.maxLength;
    var errors = [];

    if(string.length < minLength || string.length > maxLength) {
        errors = [...errors, `${name} musi mieć minimum ${minLength} znaków i nie więcej niż ${maxLength}.`];
    }
    if(!string.match(/^[A-Za-z0-9]+$/)) {
        errors = [...errors, `${name} może zawierać tylko cyfry i litery.`];
    }

    return errors;   
}

export default stringValidation;