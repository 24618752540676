import React from 'react';
import Message from './Message';

const Input = (props) => {
    return (
        <>
            {props.text &&
            <label htmlFor={props.name}>{props.text}</label>}

            <input type={props.type} id={props.name} required={props.required} placeholder={props.placeholder} onChange={ e => props.handle(e.target.value) } value={props.value}  />

            <Message info={props.errors} class="error" />
        </>
    );
}

export default Input;