import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';

const countResultTable = async (props) => {
    var stringName = props.stringName;
    var tableName = props.tableName;
    var tableField = props.tableField;
    var tableValue = props.checkString;
    var errors = [];

    await axios({
        method: 'post',
        url: `${ApiUrl()}/getCountRecords`,
        data: {
            table: tableName,
            tableField: tableField,
            tableValue: tableValue
        }
    }).then(response => {
        let res = JSON.parse(response.request.response);
        if(res.count > 0) {
            errors = [...errors, `${stringName} ${tableValue} istnieje już w naszej bazie danych.`];
        }
    });

    return errors;
}

export default countResultTable;