import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import DataRepeater from './../components/DataRepeater';

import { useSelector, useDispatch } from 'react-redux'

const LibraryVideos = () => {
    const dispatch = useDispatch();
    let mapPathRedux = useSelector(state => state.mapPath);
    let vrRedux = useSelector(state => state.gallery360);
    let [ mapPath, setMapPath ] = useState('');
    let [ activeIndex, setActiveIndex ] = useState(99999999999);
    let [ optionsSelectVr, setOptionsSelectVr ] = useState([]);

    useEffect(() => {
        setMapPath(mapPathRedux);
    }, [mapPathRedux])

    useEffect(() => {
        var newOptionsVr = optionsSelectVr;
        vrRedux && vrRedux.map((vrItem, i) => {
            newOptionsVr = [... newOptionsVr, {
                text: vrItem.name,
                value: vrItem.id
            }]

            setOptionsSelectVr(newOptionsVr);
        })
    }, [vrRedux])

    const addNewVr = () => {
        setActiveIndex(99999999999)
        var newMapPath = [''];
        mapPath && mapPath.map(item => newMapPath.push(item))
        setMapPath(newMapPath);
    }

    const addToRedux = (data) => {
        dispatch({ type: 'MAP_PATH', value: data })
    }
    return (
        <div className="library-vr library-video">
            <div className="actions">
                <button type="button" className={"btn"} onClick={ () => addNewVr() }>Dodaj</button> 
            </div>
            <div className="vr-iframe">
                {mapPath && mapPath.map((item, i) => (
                    <div key={i} className="vr-box">
                        <div className="vr-name" onClick={() => { i == activeIndex ? setActiveIndex(99999999999) : setActiveIndex(i) } }>
                            <span>{item.name ? item.name : "Nowy"}</span>
                        </div>
                        {i == activeIndex &&
                            <DataRepeater setRedux={(data) => addToRedux(data)} data={item} close={() => setActiveIndex(99999999999)} number={i} showNewInputs={mapPath} setNewInputs={ (newObject) => setMapPath(newObject) } vrOptions={optionsSelectVr} />
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LibraryVideos;