import React from 'react';
import SiteInfo from './../partials/SiteInfo';

const Welcome = () => {
    return (
        <div className="container">
            <SiteInfo />
        </div>
    );
}

export default Welcome;
