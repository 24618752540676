const ApiUrl = () => {

    let link = '';
    if ( window.location.hostname === 'localhost') {
        // link = 'https://api-cisystaropolskie.hekko24.pl/api'
        link = 'https://api.cisystaropolskie.pl/api'
    }else{
        // link = 'https://api-cisystaropolskie.hekko24.pl/api'
        link = 'https://api.cisystaropolskie.pl/api'
    }

    return link;
}

export default ApiUrl;