import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';

let results = [];
export default class UsersTable extends Component {
    async componentDidMount() {
      this.getUsers();
    }
    constructor(props) {
      super(props)

      this.state = {
        users: [],
        removeUserWindow: 0,
        removeUser: [],
      }
    }

    async getUsers() {
      await axios({
          method: 'get',
          url: `${ApiUrl()}/pagesList`,
          headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
      }).then(response => {
          results = JSON.parse(response.request.response).data;
      });

      results.map(user => {
        this.setState(prevState => ({
          users: [...prevState.users, { 
            id            : user['id'],
            name          : user['name'],
            createdAt     : user['created_at'],
            user_id       : user['user_id'],
          }]
        }))
      })
    }

    render() {
       
        const columns = [
        {
          Header: 'ID',
          accessor: 'id'
        },
        {
          Header: 'Nazwa',
          accessor: 'name'
        },
        {
          Header: 'Stworzył',
          Cell: props => <div> { props.original.user_id.login } </div>,
        },
        {
          Header: 'Utworzono',
          accessor: 'createdAt',
        },
      ]

        return (
          <div className={`column col-${ this.props.col }`}>
            <ReactTable
              data={this.state.users}
              columns={columns}
              getTrProps={(state, rowInfo) => ({
                onClick: () => this.props.This.history.push("pages/page/" + rowInfo.original.id)
              })}
            />
          </div>
          
        )
    }
}