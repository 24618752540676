import React, { useState, useEffect } from 'react'
import VrUrl from './../../../services/VrUrl';

const VrRepeater = ( props ) => {
    
    const removeVr = (itemIndex) => {
        var newArray = props.showNewInputs;
        newArray = newArray.filter((item, index) => index != itemIndex);
        props.setNewInputs(newArray);
        if(props.showNewInputs[itemIndex] && props.showNewInputs[itemIndex].id) {
            props.removeVr(props.showNewInputs[itemIndex].id, newArray);
        }
    }

    return (
        <div className="image-repeater-wrapper">
            <div className="vr-row">
                <div className="remove-vr"><span onClick={ () => removeVr(props.number) }>Usuń</span></div>
                <div className="items">
                    <iframe id="inlineFrameExample"
                        title="vr"
                        width="100%"
                        height="600"
                        src={`${VrUrl()}?srcLoad=${props.data.id ? props.data.id : 0}&token=${localStorage.getItem('token')}&userID=${localStorage.getItem('userId')}`}>
                    </iframe>
                </div>
            </div>
        </div>  
    );
}

export default VrRepeater;