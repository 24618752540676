import React, { useState, useEffect } from 'react'
import Input from './../components/Input';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import SelectItems from './../components/SelectItems';
import Select from './../components/Select';

const QuizRepeater = ( props ) => {
    let [ load, setLoad ] = useState(0);
    let [ questionName, setQuestionName ] = useState('');
    let [ difficultyLevel, setDifficultyLevel ] = useState('');
    let [ questionImage, setQuestionImage ] = useState('');
    let [ a, setA ] = useState('');
    let [ b, setB ] = useState('');
    let [ c, setC ] = useState('');
    let [ d, setD ] = useState('');
    let [ correctAnswer, setCorrectAnswer ] = useState('');
    let [ id, setId ] = useState(0);
    let [ disableBtn, setDisableBtn ] = useState(false);
    let [ media, setMedia ] = useState();
    let [ display, setDisplay ] = useState('kolumna');

    useEffect(() => {
        
        if(load === 0) {
            if(props.data && props.data.id) {
                setQuestionName(props.data.question);
                setId(props.data.id);
                setDifficultyLevel(props.data.difficulty_level);
                setA(props.data.a);
                setB(props.data.b);
                setC(props.data.c);
                if(props.data.c) {
                    setD(props.data.d);
                }
                setCorrectAnswer(props.data.correct_answer);
                if(props.data.question_image) {
                    setQuestionImage(JSON.parse(props.data.question_image));
                }
                setDisplay(props.data.display);
            }else{
                setDifficultyLevel(props.groupLevel);
            }
            setMedia(props.media);
            setLoad(1);
        }

    }, [load])

    const createObject = () => {
        if(!disableBtn) {
            setDisableBtn(true);
            var errors = false;

            if(!questionName || questionName.length == 0) {
                alert('Należy wprowadzić pytanie');
                errors = true;
            }

            if(!a || a.length == 0) {
                alert('Należy wprowadzić odpowiedź A');
                errors = true;
            }

            if(!b || b.length == 0) {
                alert('Należy wprowadzić odpowiedź B');
                errors = true;
            }

            if(!c || c.length == 0) {
                alert('Należy wprowadzić odpowiedź C');
                errors = true;
            }

            if(!correctAnswer || b.correctAnswer == 0) {
                alert('Należy wprowadzić prawidłową odpowiedź');
                errors = true;
            }

            if(errors) {
                setDisableBtn(false);
            }
            if(!errors) {
                axios({
                    method: 'post',
                    url: `${ApiUrl()}/quizChange`,
                    data: {
                        token: localStorage.getItem('token'),
                        userID: localStorage.getItem('userId'),
                        id: id,
                        difficultyLevel: difficultyLevel,
                        questionName: questionName,
                        questionImage: questionImage,
                        a: a,
                        b: b,
                        c: c,
                        d: d,
                        correctAnswer: correctAnswer,
                        display: display
                    }
                }).then(response => {
                    let res = JSON.parse(response.request.response);
                    var newQuiz = props.showNewInputs;
                    newQuiz[props.indexGroup].questions[props.indexQuestion] = res;
                    props.setRedux(newQuiz);
                    setDisableBtn(false);
                    alert('Zapisano');
                })
            }
        }
    }

    let optionDisplay = [
        {
            value: "kolumna",
            text: "kolumna"
        }, 
        {
            value: "linia",
            text: "linia"
        }
    ]

    const removeItem = (Id) => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/removeQuiz`,
            data: {
                token: localStorage.getItem('token'),
                id: Id,
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status == "success") {
                var newQuestions = [];
                props.showNewInputs && props.showNewInputs.map((item, i) => {
                    if(i == props.indexGroup) {
                        let array = item;
                        let itemQuestion = item.questions;
                        itemQuestion = itemQuestion.filter((item, i) => i !== props.indexQuestion)
                        array.questions = itemQuestion
                        newQuestions.push(array);
                    }else{
                        newQuestions.push(item);
                    }
                })
                
                props.setRedux(newQuestions);
                props.close();
            }
        })
    }

    return (
        <div className="video-repeater-wrapper">
            <div className="remove-vr">
                {id != 0 && 
                    <span onClick={() => removeItem(id)}>Usuń</span>
                }
            </div>
            <div className="data">
                <div className="inputs">
                    <div className="row">
                        <Input name="question"
                            label="Pytanie"
                            type="text" 
                            value={ questionName }
                            disabled={ false } 
                            handle={ (val) => { setQuestionName(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Input name="a"
                            label="Odpowiedź A"
                            type="text" 
                            value={ a }
                            disabled={ false } 
                            handle={ (val) => { setA(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Input name="b"
                            label="Odpowiedź B"
                            type="text" 
                            value={ b }
                            disabled={ false } 
                            handle={ (val) => { setB(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Input name="c"
                            label="Odpowiedź C"
                            type="text" 
                            value={ c }
                            disabled={ false } 
                            handle={ (val) => { setC(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Input name="d"
                            label="Odpowiedź D"
                            type="text" 
                            value={ d }
                            disabled={ false } 
                            handle={ (val) => { setD(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Input name="correct"
                            label="Prawidłowa odpowiedź (literka np. a)"
                            type="text" 
                            value={ correctAnswer }
                            disabled={ false } 
                            handle={ (val) => { setCorrectAnswer(val) } } 
                            error={null}
                            column="xs-12" />
                    </div>
                    <div className="row">
                        <Select name="display"
                            label="Wyświetl odpowiedzi jako"
                            data={ optionDisplay } 
                            value={ display }
                            handle={ (val) => { setDisplay(val) } } 
                            column="xs-12" />
                    </div>
                    <SelectItems name="images"
                        label="Zdjęcie"
                        data={ media } 
                        value={ questionImage }
                        handle={ (val) => setQuestionImage(val) } 
                        column="xs-12" />
                    <div className="submit">
                        <input type="button" value="Zapisz" className="btn" onClick={() => createObject()} />
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default QuizRepeater;
