import React, { useState } from 'react'

const Input = ( props ) => {
    return (
        <div className={ `col-${ props.column }` } >
            <div className="form-group bmd-form-group">
                <label className={props.error ? "bmd-label-floating error" : "bmd-label-floating"} htmlFor={ props.name } > { props.label } </label>
                <input className="form-control" type={ props.type } name={ props.name }  disabled={ props.disabled ? 'disabled' : null} value={ props.value } onInput={ (e) => props.handle(e.target.value) }/>
                {/* onBlur={ () => props.handleBlur()}  */}
            </div>
        </div>   
    );
}

export default Input;
