import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import VrRepeater from './../components/VrRepeater';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { useSelector, useDispatch } from 'react-redux'

const LibraryVr = () => {
    const dispatch = useDispatch();
    let galleryVr = useSelector(state => state.gallery360);
    let [ gallery360, setGallery360 ] = useState('');
    let [ activeIndex, setActiveIndex ] = useState(99999999999);

    useEffect(() => {
        setGallery360(galleryVr);
    }, [galleryVr])

    const addNewVr = () => {
        setActiveIndex(99999999999)
        var newGallery360 = [''];
        gallery360 && gallery360.map(item => newGallery360.push(item))
        setGallery360(newGallery360);
    }

    const removeVr = (vrId, newArray) => {
        dispatch({ type: 'GALLERY_360', value: newArray })
        axios({
            method: 'post',
            url: `${ApiUrl()}/removeVr`,
            data: {
                token: localStorage.getItem('token'),
                id: vrId,
            }
        }).then(response => {
        })
    }

    const saveOrder = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/saveOrderVr`,
            data: {
                token: localStorage.getItem('token'),
                items: gallery360,
            }
        }).then(response => {
            alert('Zapisano kolejność VR.');
        })
    }

    const handleRLDDChange = (newItems) => {
        setGallery360(newItems);
    }

    return (
        <div className="library-vr">
            <div className="actions">
                <button type="button" className={"btn"} onClick={ () => { addNewVr() } }>Dodaj nowy vr</button> 
            </div>
            <div className="vr-iframe">
                {/* {gallery360 && gallery360.map((item, i) => (
                    <div key={i} className="vr-box" onClick={() => { i == activeIndex ? setActiveIndex(99999999999) : setActiveIndex(i) } }>
                        <div className="vr-name">
                            <span>{item.name ? item.name : "Nowy"}</span>
                        </div>
                        {i == activeIndex &&
                            <VrRepeater data={item} number={i} showNewInputs={gallery360} setNewInputs={ (newObject) => setGallery360(newObject) } removeVr={(vrId, newArray) => removeVr(vrId, newArray)} />
                        }
                    </div>
                ))} */}
                {gallery360 &&
                    <RLDD
                        items={gallery360}
                        itemRenderer={(item, i) => {
                            return (
                                <div key={i} className="vr-box" onClick={() => { i == activeIndex ? setActiveIndex(99999999999) : setActiveIndex(i) } }>
                                    <div className="vr-name">
                                        <span>{item.name ? item.name : "Nowy"}</span>
                                    </div>
                                    {i == activeIndex &&
                                        <VrRepeater data={item} number={i} showNewInputs={gallery360} setNewInputs={ (newObject) => setGallery360(newObject) } removeVr={(vrId, newArray) => removeVr(vrId, newArray)} />
                                    }
                                </div>
                            );
                        }}
                        onChange={(val) => handleRLDDChange(val)}
                    />
                }
            </div>
            <div className="actions">
                <button type="button" className={"btn"} onClick={ () => { saveOrder() } }>Zapisz kolejność</button>
            </div>
        </div>
    );
}

export default LibraryVr;


// {gallery360 && gallery360.map((item, i) => (
//     <div key={i} className="vr-box" onClick={() => { i == activeIndex ? setActiveIndex(99999999999) : setActiveIndex(i) } }>
//         <div className="vr-name">
//             <span>{item.name ? item.name : "Nowy"}</span>
//         </div>
//         {i == activeIndex &&
//             <VrRepeater data={item} number={i} showNewInputs={gallery360} setNewInputs={ (newObject) => setGallery360(newObject) } removeVr={(vrId, newArray) => removeVr(vrId, newArray)} />
//         }
//     </div>
// ))}