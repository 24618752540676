const checkSession = (storageDateTime, actualDateTime, addMinutes) => {
    function createDateObject(date) {
        let year = date.substring(0,4);
        let month = date.substring(5, 7);
        let day = date.substring(8,10)
        let hour = date.substring(11, 13);
        let minutes = date.substring(14, 16);
        let seconds = date.substring( 17, 19);

        let object = new Date(year, month, day, hour, minutes, seconds)
        return object;
    }
    let storageObject = createDateObject(storageDateTime);
    let storageObjectWithMinutes = new Date(storageObject.getTime() + addMinutes * 60000);
    let actualDateObject = createDateObject(actualDateTime);

    storageObjectWithMinutes = storageObjectWithMinutes.getTime() / 1000;
    actualDateObject = actualDateObject.getTime() / 1000;

    if(storageObjectWithMinutes > actualDateObject) {
        return true
    }else{
        return false
    }

}

export default checkSession;