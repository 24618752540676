import React, { useState, useEffect } from 'react'
import close from './../../../img/close.svg';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'

const LibraryPopup = ( props ) => {
    let openIndex = props.openIndex;
    let [ description, setDescription ] = useState('');
    let [ load, setLoad ] = useState(false);
    let [ alt, setAlt ] = useState('');
    let [ name, setName ] = useState('');
    let media = useSelector(state => state.media);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if(!load) {
    //         setAlt(image.alt);
    //         setDescription(image.description);
    //         console.log('load')
    //     }
    //     setLoad(true);
    // }, [load])

    useEffect(() => {
        setAlt(props.data.alt);
        setDescription(props.data.description);
        setName(props.data.name);
    }, [props.page])

    const updateImage = (column) => {
        if(alt !== props.data.alt || description !== props.data.description || name !== props.data.name) {
            axios({
                method: 'post',
                url: `${ApiUrl()}/updateImageLibrary`,
                data: {
                    column: column,
                    imageID: props.data.id,
                    alt: alt,
                    description: description,
                    name: name,
                    token: localStorage.getItem('token')
                },
            }).then(response => {
                let res = JSON.parse(response.request.response);
            })
        }
    }

    const removeImage = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/removeImageLibrary`,
            data: {
                imageID: props.data.id,
                token: localStorage.getItem('token')
            },
        }).then(response => {
            let res = JSON.parse(response.request.response);
            let data = props.gallery.filter((item, index) => index != openIndex);
            dispatch({ type: 'GALLERY_2D', value: data })
            props.setIndex(999999);
            let newMedia = media.filter(item => item.id !== props.data.id);
            dispatch({ type: 'MEDIA', value: newMedia })
        })
    }

    return (
        <>
        {openIndex == props.index &&
        <div className="library-popup">
            <div className="image-box-popup">
                <img src={props.data.sizes.small} alt={props.data.alt} />
            </div>
            <div className="information-img">
                <div className="exit"><img src={close} alt="zamknij" onClick={() => { props.setIndex(999999); updateImage(["alt", "description", "name"]) } } /></div>
                <div className="info">
                    <span className="label">Nazwa: </span>
                    <input type="text" value={name} className="altInput value" onInput={(e) => setName(e.target.value)} />
                </div>
                <div className="info">
                    <span className="label">ALT: </span>
                    <input type="text" value={alt} className="altInput value" onInput={(e) => setAlt(e.target.value)} />
                </div>
                <div className="info">
                    <span className="label">Opis: </span>
                    <input type="text" value={description} className="altInput value" onInput={(e) => setDescription(e.target.value)} />
                </div>
                <div className="info">
                    <span className="label">Data przesłania: </span>
                    <span className="value">{ props.data.created_at }</span>
                </div>
                <div className="info">
                    <span className="label">Waga: </span>
                    <span className="value">{ props.data.weight }</span>
                </div>
                <div className="info">
                    <span className="label">Rozmiar(wgrany): </span>
                    <span className="value">{ props.data.size }</span>
                </div>
                <div className="info">
                    <span className="label">Kto wgrał: </span>
                    <span className="value">{ props.data.user_id.login }</span>
                </div>
                <div className="info">
                    <button type="button" className="btn" onClick={() => removeImage()}>Usuń</button> 
                </div>
            </div>
        </div>  
        }
        </>
    );
}

export default LibraryPopup;
