const emailValidation = (props) => {
    var email = props.emailName;
    var string = props.checkEmail;
    var errors = [];

    if(!string.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        errors = [...errors, `Podany ${email} ma zły format`];
    }
    
    return errors;
}

export default emailValidation;