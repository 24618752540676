export const onStart = (state = [], action) => { 
    switch (action.type) {
        case 'USER_DATA':
            return action.value;      
        default:
            return state
    }
}

export const gallery = (state = [], action) => { 
    switch (action.type) {
        case 'GALLERY_2D':
            return action.value;      
        default:
            return state
    }
}

export const lastPageGallery = (state = 1, action) => { 
    switch (action.type) {
        case 'LAST_PAGE_GALLERY':
            return action.value;      
        default:
            return state
    }
}

export const gallery360 = (state = [], action) => { 
    switch (action.type) {
        case 'GALLERY_360':
            return action.value;      
        default:
            return state
    }
}

export const galleryVideos = (state = [], action) => { 
    switch (action.type) {
        case 'GALLERY_VIDEOS':
            return action.value;      
        default:
            return state
    }
}

export const mapPath = (state = [], action) => { 
    switch (action.type) {
        case 'MAP_PATH':
            return action.value;      
        default:
            return state
    }
}

export const quiz = (state = [], action) => { 
    switch (action.type) {
        case 'QUIZ':
            return action.value;      
        default:
            return state
    }
}

export const gallerySets = (state = [], action) => { 
    switch (action.type) {
        case 'GALLERY_SETS':
            return action.value;      
        default:
            return state
    }
}

export const media = (state = [], action) => { 
    switch (action.type) {
        case 'MEDIA':
            return action.value;      
        default:
            return state
    }
}