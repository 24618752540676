import React, { useState,useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import '../../scss/dashboard.scss';
import Header from './partials/Header'
import Navbar from './partials/Navbar'
import Welcome from './pages/Welcome';
import Users from './pages/Users';
import User from './pages/User';
import Library from './pages/Library';
import LibraryVr from './pages/LibraryVr';
import LibraryVideos from './pages/LibraryVideos';
import Pages from './pages/Pages';
import NewPage from './pages/NewPage';
import Page from './pages/Page';
import MapPath from './pages/MapPath';
import Quiz from './pages/Quiz';
import GallerySets from './pages/GallerySets';
import ApiUrl from './../../services/ApiUrl';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'

let userData = [];
const DashboardView = (props) => {
    const dispatch = useDispatch();
    let [load, setLoad] = useState(0);
    
    useEffect(() => {
        if (load == 0) {
            ;(async function() {
                await sessionUser();
                await getUser();
                await getGallery();
                await getGalleryVr();
                await getGalleryVideos();
                await getMapPath();
                await getQuiz();
                await getGallerySets();
                await getMedia();
                await setLoad(1);
            })()
        }
    },[load]);

    const sessionUser = async () => {
        var currentUser = localStorage.getItem('token') ? true : false;
        if (!currentUser) {
            props.history.push({ pathname: '/login' });
        }
    }

    const getUser = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/me`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }

        }).then(response => {
            let res = JSON.parse(response.request.response);
            userData = res;
            dispatch({ type: 'USER_DATA', value: userData })
        });
    }

    const getGallery = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/galleryItems`,
            data: {
                token: localStorage.getItem('token'),
                page: 1,
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'GALLERY_2D', value: res.data })
            dispatch({ type: 'LAST_PAGE_GALLERY', value: res.meta.last_page })
        })
    }

    const getGalleryVr = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getGalleryVr?token=${localStorage.getItem('token')}`,
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'GALLERY_360', value: res.data })
        })
    }

    const getGalleryVideos = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getGalleryVideos?token=${localStorage.getItem('token')}`,
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'GALLERY_VIDEOS', value: res.data })
        })
    }

    const getMapPath = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getMapPath?token=${localStorage.getItem('token')}`,
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'MAP_PATH', value: res })
        })
    }

    const getQuiz = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getQuiz?token=${localStorage.getItem('token')}`,
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'QUIZ', value: res })
        })
    }

    const getGallerySets = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getGallerySets?token=${localStorage.getItem('token')}`,
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'GALLERY_SETS', value: res })
        })
    }

    const getMedia = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/media`,
            data: {
                token: localStorage.getItem('token')
            },
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'MEDIA', value: res })

        })
    }

    return (
        <>
            <div className="wrapper">
                <Navbar />
                <main>
                    <Header props={props} user={userData} />
                    <Switch>
                        <Route path={`/dashboard`} exact component={ Welcome } />
                        <Route path={`/dashboard/users`} exact component={ Users } />
                        <Route path={`/dashboard/users/:id`} component={ User } />
                        <Route path={`/dashboard/library`} exact component={ Library } />
                        <Route path={`/dashboard/library-vr`} exact component={ LibraryVr } />
                        <Route path={`/dashboard/library-videos`} exact component={ LibraryVideos } />
                        <Route path={`/dashboard/pages`} exact component={ Pages } />
                        <Route path={`/dashboard/pages/page/:id`} exact component={ Page } />
                        <Route path={`/dashboard/pages/new`} exact component={ NewPage } />
                        <Route path={`/dashboard/map-path`} exact component={ MapPath } />
                        <Route path={`/dashboard/quiz`} exact component={ Quiz } />
                        <Route path={`/dashboard/gallery-sets`} exact component={ GallerySets } />
                    </Switch>
                </main>
            </div>
        </>
    );
}

export default DashboardView;